import React from 'react'
import Paragraph from '../../shared/Paragraph'
import SecondaryHeading from '../../shared/SecondaryHeading'
import MarketPlaceCarousel from './MarketPlaceCarousel'

import { Container } from 'react-grid-system'

const MarketPlaceSection = ({
  primaryHeading = 'no title',
  secondaryHeading = 'no title',
  description = ' no description',
  themeList = []
}) => {
  return (
    <section className="marketPlaceSection">
      <Container className="marketPlaceSection__container">
        <div className="marketPlaceSection__body">
          <SecondaryHeading
            primaryText={primaryHeading}
            secondaryText={secondaryHeading}
          />
          <Paragraph
            paragraphText={description}
            textColorClass={'secondary'}
            customClass={'marketPlaceSection__text'}
            alignCenter={true}
          />
          <MarketPlaceCarousel themeList={themeList} />
        </div>
      </Container>
    </section>
  )
}

export default MarketPlaceSection
