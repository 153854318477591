import React, { useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import AppTabPanelContentPrimary from './AppTabPanelContentPrimary'
import TabIcon from './AppTabPanelContentPrimary/TabIcon'

const AppTabs = ({ tabsData = [], activeTabClass, btnColorClass }) => {
  const [renderClick, setRenderClick] = useState(false)
  const renderFunc = () => setRenderClick(!renderClick)

  console.log(tabsData[1])
  return (
    <div className="appTabs">
      <Tabs>
        <TabList
          className={`appTabs__headingWrapper appTabs__headingWrapper--${
            activeTabClass || ''
          }`}
        >
          {tabsData.map(({ tabTitle }, index) => (
            <Tab
              key={index}
              className={`appTabs__tabHeading appTabs__tabHeading--${
                activeTabClass || ''
              }`}
              selectedClassName={
                activeTabClass
                  ? 'active_icon appTabs__tabHeading__active--' +
                      activeTabClass || ''
                  : 'active_icon appTabs__tabHeading__active--primary'
              }
              onClick={renderFunc}
              style={{ position: 'relative' }}
            >
              <span
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  top: '0',
                  left: '0',
                  zIndex: '1'
                }}
                id={`tab-btn-${tabTitle.toLowerCase().split(' ').join('-')}`}
              />
              <TabIcon name={tabTitle} onChangeRender={renderClick} />
              <p className="appTabs__tabHeading__text">{tabTitle}</p>
            </Tab>
          ))}
        </TabList>

        {tabsData.map(
          (
            {
              tabDescription = 'lorem ipsum ',
              tabButton = { buttonText: 'no text', buttonLink: 'no Link' },
              tabTitle = 'notitle',
              tabVideo
            },
            index
          ) => (
            <TabPanel key={index}>
              <AppTabPanelContentPrimary
                btnColorClass={btnColorClass}
                btnLink={tabButton.buttonLink}
                btnText={tabButton.buttonText}
                description={tabDescription}
                heading={tabTitle}
                tabVideo={tabVideo}
                icon={tabTitle}
                BtnId={`get-started-${tabTitle
                  .toLowerCase()
                  .split(' ')
                  .join('-')}`}
              />
            </TabPanel>
          )
        )}
      </Tabs>
    </div>
  )
}

export default AppTabs
