import React from 'react'
import { Container, Row, Col } from 'react-grid-system'
import AppIcon from '../../shared/AppIcon'
import Paragraph from '../../shared/Paragraph'
import Primarybutton from '../../shared/Primarybutton'
import SecondaryHeading from '../../shared/SecondaryHeading'
import logo from '../FooterSection/footerLogo.png'
function ComparisonSection({
  primaryHeading = 'no title',
  secondaryHeading = 'no description',
  tertiaryHeading = 'no description',
  tertiaryHeadingHighlight = 'no description',
  description = 'no desc',
  primaryButton = { buttonText: 'no text', buttonLink: 'no Link' }
}) {
  return (
    <section className="comparisonSection">
      <Container className="comparisonSection__container">
        <div className="comparisonSection__wrapper">
          <SecondaryHeading
            primaryText={primaryHeading}
            secondaryText={secondaryHeading}
          />
          <div className="comparisonSection__body">
            <Container className="comparisonSection__body__container">
              <Row>
                <Col md={12} lg={4}>
                  <div className="comparisonSection__left">
                    <h3 className="comparisonSection__body__heading">
                      {tertiaryHeading}
                      <span className="comparisonSection__body__heading__highlight">

                        {tertiaryHeadingHighlight}
                      </span>
                    </h3>
                    <Paragraph
                      paragraphText={description}
                      textColorClass={'secondary'}
                      customClass={'comparisonSection__body__desc'}
                    />

                    <Primarybutton
                      buttonText={primaryButton.buttonText}
                      buttonLink={primaryButton.buttonLink}
                      noHover={true}
                      customClass={'comparisonSection__primaryBtn'}
                      buttonId={`get-started-${primaryHeading}`}
                    />
                  </div>
                </Col>
                <Col md={12} lg={8}>
                  <div className="comparison_right_side_table">
                    <div className="wraper_table_con">
                      <div className="wraper_table_inner">
                        <div className="table_heading">
                          <div className="heading_row">
                            <div className="content_table"></div>
                            <div className="logo_table">
                              <img
                                src={logo}
                                alt={logo}
                                draggable="false"
                                className="logo_top"
                              />
                            </div>
                            <div className="others_table">others</div>
                          </div>
                        </div>
                        <div className="table_body">
                          <div className="body_row">
                            <div className="content_table">
                              Designed for simplicity
                            </div>
                            <div className="logo_table">
                              <AppIcon iconName="tick" />
                            </div>
                            <div className="others_table">
                              <AppIcon iconName="cross" />
                            </div>
                          </div>
                          <div className="body_row">
                            <div className="content_table">Fun to use</div>
                            <div className="logo_table">
                              <AppIcon iconName="tick" />
                            </div>
                            <div className="others_table">
                              <AppIcon iconName="cross" />
                            </div>
                          </div>
                          <div className="body_row">
                            <div className="content_table">
                              Designed for maximum productivity
                            </div>
                            <div className="logo_table">
                              <AppIcon iconName="tick" />
                            </div>
                            <div className="others_table">
                              <AppIcon iconName="cross" />
                            </div>
                          </div>
                          <div className="body_row">
                            <div className="content_table">
                              Naturally increases organization
                            </div>
                            <div className="logo_table">
                              <AppIcon iconName="tick" />
                            </div>
                            <div className="others_table">
                              <AppIcon iconName="cross" />
                            </div>
                          </div>
                          <div className="body_row">
                            <div className="content_table">
                              Reduces mental clutter
                            </div>
                            <div className="logo_table">
                              <AppIcon iconName="tick" />
                            </div>
                            <div className="others_table">
                              <AppIcon iconName="cross" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default ComparisonSection
