import React, { useRef, useEffect, useContext } from 'react'
import { graphql } from 'gatsby'
import '@szhsin/react-menu/dist/index.css'
import HeroSection from '../../components/PageComponents/HeroSection'
import MagicTaskSection from '../../components/PageComponents/MagicTaskSection'
import EasyToUseSection from '../../components/PageComponents/EasyToUseSection'
import FunToUseSection from '../../components/PageComponents/FunToUseSection'
import SaveTimeSection from '../../components/PageComponents/SaveTimeSection'
import ComparisonSection from '../../components/PageComponents/ComparisonSection'
import MarketPlaceSection from '../../components/PageComponents/MarketPlaceSection'
import SubscribeSection from '../../components/PageComponents/SubscribeSection'
import Testimonials from '../../components/PageComponents/Testimonials'
import FAQCustomersection from '../../components/PageComponents/FAQsection'
import Header from '../../components/shared/Header'
import ThemeChanger from '../../components/shared/ThemeChanger'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import FooterSection from '../../components/PageComponents/FooterSection'
import Layout from '../../components/StructureComponents/Layout'
import { GlobalContext } from '../../context/GlobalState'
// const SubscribeSection=loadable(()=>{
//   import ('../../components/PageComponents/SubscribeSection');
// });
function HomePageTemplate(props) {
  const ref = useRef(null)
  let prevScrollpos = typeof window !== 'undefined' ? window.pageYOffset : 0
  const handleScroll = () => {
    if (ref.current && typeof window !== 'undefined') {
      const maxScroll = document.body.clientHeight - window.innerHeight
      let currentScrollPos = window.pageYOffset
      if (
        (maxScroll > 0 &&
          prevScrollpos > currentScrollPos &&
          prevScrollpos <= maxScroll) ||
        (maxScroll <= 0 && prevScrollpos > currentScrollPos) ||
        (prevScrollpos <= 0 && currentScrollPos <= 0)
      ) {
        if (window.innerWidth > 1024) {
          document.getElementById('site-header').style.top = '0'
          document.getElementById('site-header').style.bottom = 'auto'
        } else {
          document.getElementById('site-header').style.top = 'auto'
          document.getElementById('site-header').style.bottom = '0'
        }
      } else {
        if (window.innerWidth > 1024) {
          document.getElementById('site-header').style.top = '-1000px'
          document.getElementById('site-header').style.bottom = 'auto'
        } else {
          document.getElementById('site-header').style.top = 'auto'
          document.getElementById('site-header').style.bottom = '-1000px'
        }
      }
      prevScrollpos = currentScrollPos
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll)

      return () => {
        window.removeEventListener('scroll', () => handleScroll)
      }
    }
  }, [])

  //-----------------------------------
  const { isChangingTheme } = useContext(GlobalContext)
  //-----------------------------------

  return (
    <Layout meta={props?.meta}>
      {isChangingTheme ? (
        <></>
      ) : (
        <div className="home-page-container">
          <ThemeChanger />
          <Header />
          <HeroSection {...props.herosection} />
          <div className="maxWidthcontainer">
            <MagicTaskSection {...props.Magictask} />
            <EasyToUseSection {...props.easyToUseSection} />
            <FunToUseSection {...props.funToUseSection} />
            <SaveTimeSection {...props.saveTimeeSection} />
            <ComparisonSection {...props.comparisonSection} />
            <MarketPlaceSection {...props.marketPlaceSection} />
            <FAQCustomersection {...props.faqSection} />
            <Testimonials {...props.testimonialsSection} />
            <SubscribeSection {...props.subscribeSection} />
          </div>
          <FooterSection {...props.footersection} />
        </div>
      )}
    </Layout>
  )
}
const Home = ({ data: { page, blogs } }) => (
  <HomePageTemplate {...page} blogs={blogs?.edges} {...page.frontmatter} />
)

export default Home

export const pageQuery = graphql`
  ## Query for EbookIndex data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## query name must be unique to this file
  query Home {
    page: markdownRemark(frontmatter: { template: { eq: "HomePage" } }) {
      html
      frontmatter {
        meta {
          title
          description
          ogimage
          canonicalLink
        }

        status
        title

        herosection {
          title
          description
          secondaryButton {
            buttonText
            buttonLink
          }
          primaryButton {
            buttonText
            buttonLink
          }
        }
        Magictask {
          primaryHeading
          secondaryHeading
          description
        }

        subscribeSection {
          title
          description
          secondaryButton {
            buttonText
            buttonLink
          }
        }

        easyToUseSection {
          primaryHeading
          secondaryHeading
          description
          easyToUseSectionTabs {
            tabTitle
            tabIcon
            tabVideo {
              clean_theme_video
              darkmode_theme_video
              arcade_theme_video
              gaming_theme_video
              glitch_theme_video
              mcars_theme_video
            }
            tabDescription
            tabButton {
              buttonText
              buttonLink
            }
          }
        }

        funToUseSection {
          primaryHeading
          secondaryHeading
          description
          funToUseTabs {
            tabTitle
            tabIcon
            tabDescription
            tabVideo {
              clean_theme_video
              darkmode_theme_video
              arcade_theme_video
              gaming_theme_video
              glitch_theme_video
              mcars_theme_video
            }
            tabButton {
              buttonText
              buttonLink
            }
          }
        }

        saveTimeeSection {
          primaryHeading
          secondaryHeading
          description
          saveTimeTabs {
            tabTitle
            tabIcon
            tabDescription
            tabVideo {
              clean_theme_video
              darkmode_theme_video
              arcade_theme_video
              gaming_theme_video
              glitch_theme_video
              mcars_theme_video
            }
            tabButton {
              buttonText
              buttonLink
            }
          }
        }

        comparisonSection {
          primaryHeading
          secondaryHeading
          tertiaryHeading
          tertiaryHeadingHighlight
          description
          primaryButton {
            buttonText
            buttonLink
          }
        }

        marketPlaceSection {
          primaryHeading
          secondaryHeading
          description
          themeList {
            themeName
            themeImage
            primaryButton {
              buttonText
              buttonLink
            }
          }
        }

        subscribeSection {
          title
          description
          secondaryButton {
            buttonText
            buttonLink
          }
        }

        testimonialsSection {
          primaryHeading
          secondaryHeading
          description
          testimonialsList {
            authorName
            comment
            authorImage
          }
        }

        faqSection {
          primaryHeading
          secondaryHeading
          description
          faqList {
            question
            answer
          }
        }
        footersection {
          primaryHeading
          description
          secondaryheading
          tertiaryheading
          footerList {
            primaryheading
            footerLink
          }
        }
      }
    }

    # ---
    # END OF QUERY
  }
`
