import React from 'react'
import { getCurrentTheme } from '../../../utils'

const ThemeImageLoader = ({
  imgName = 'magicTaskDescktop',
  imgExtention = 'webp',
  isIcon = false,
  customClass
}) => {
  const rootPath = `/images/${getCurrentTheme().id}`

  const imagePath = isIcon
    ? `${rootPath}/icons/${imgName}.${imgExtention}`
    : `${rootPath}/${imgName}.${imgExtention}`

  return (
    <img
      src={imagePath}
      className={`themeImageLoaderImg  ${customClass ?? ''}`}
      alt={`${imgName}`}
    />
  )
}

export default ThemeImageLoader
