import React, { useState } from 'react'

const SingleCard = ({
  direction,
  heading,
  description,
  authorImage = 'https://picsum.photos/100/100',
  expanded = false
}) => {
  const [showFullText, setShowFullText] = useState(expanded)

  return (
    <>
      <div className={`card-testimonials ${direction}`}>
        <span className='card-testimonials-bordercontainer'>

        </span>
        <div className="img-box">
          <img src={authorImage} alt="" />
        </div>
        <div className="content-box">
          <div className="top-title">
            <h4>{heading}</h4>
          </div>
          <div className="desc">
            <p className="">
              {description.length < 130 || showFullText
                ? description
                : `${description.substring(0, 130)}`}

              {!showFullText ? (
                <span
                  className="show__more__text"
                  onClick={() => {
                    setShowFullText(true)
                  }}
                >
                  &nbsp; Read More ...
                </span>
              ) : (
                <span
                  className="show__more__text"
                  onClick={() => {
                    setShowFullText(false)
                  }}
                >
                  &nbsp; Read Less
                </span>
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default SingleCard
