import React from 'react'
import { Col, Container, Row } from 'react-grid-system'
import Paragraph from '../../shared/Paragraph'
import SecondaryHeading from '../../shared/SecondaryHeading'
import SingleCard from './SingleCard'
import Slider from 'react-slick'
import { nanoid } from 'nanoid'
let carouselSettings = {
  dots: false,
  infinite: true,
  speed: 300,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false
}

const Testimonials = ({
  primaryHeading = 'no title',
  secondaryHeading = 'no description',
  description = 'no description',
  testimonialsList = []
}) => {
  var newData = []
  var j = 0
  newData.push([])
  for (let i = 1; i <= testimonialsList.length; i++) {
    newData[j].push(testimonialsList[i - 1])
    if (i % 3 == 0) {
      newData.push([])
      j++
    }
  }
  if (newData[newData.length - 1].length === 0) {
    newData.pop()
  }

  return (
    <div className="testimonials" key={nanoid()}>
      <Container className="testimonials___section" key={nanoid()}>
        <SecondaryHeading
          key={nanoid()}
          primaryText={primaryHeading}
          secondaryText={secondaryHeading}
        />

        <Paragraph
          key={nanoid()}
          paragraphText={description}
          customClass={'testimonialsSection__text'}
          textColorClass={'secondary'}
          alignCenter={true}
        />

        <Container key={nanoid()}>
          <Row className="cardsContainer">
            <Col lg={12} md={12}>
              <div className="cards-wraper">
                <Slider className="Card_slider_custom" {...carouselSettings}>
                  {newData.map((itemArr) => (
                    <div key={nanoid()}>
                      {itemArr.map((item, i) => (
                        <SingleCard
                          key={nanoid()}
                          description={item.comment}
                          heading={item.authorName}
                          authorImage={item.authorImage}
                          direction={(i + 1) % 2 === 0 ? 'right' : 'left'}
                          expanded={i === 0}
                        />
                      ))}
                    </div>
                  ))}
                </Slider>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  )
}

export default Testimonials
