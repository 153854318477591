import React, { useRef } from 'react'
import Slider from 'react-slick'
import { Container, Row, Col } from 'react-grid-system'
import AppIcon from '../../../shared/AppIcon'
import MarketPlaceCard from '../MarketPlaceCard'

let carouselSettings = {
  dots: false,
  infinite: true,
  speed: 300,
  pauseOnHover: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  responsive: [
    {
      breakpoint: 996,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}

const MarketPlaceCarousel = ({ themeList = [] }) => {
  const sliderRef = useRef()

  return (
    <div className="marketPlaceCarousel">
      <Container className="marketPlaceCarousel_containercaresol">
        <Row>
          <Col lg={12} className={'marketPlaceCarousel__slider'}>
            <Slider ref={sliderRef} {...carouselSettings}>
              {themeList.map((item) => (
                <MarketPlaceCard
                  key={item.themeName}
                  btnText={item.primaryButton.buttonText}
                  btnLink={item.primaryButton.buttonLink}
                  cardHeading={item.themeName}
                  themeImage={item.themeImage}
                />
              ))}
            </Slider>

            <div className="marketPlaceCarousel__arrow__wrapper--mobile">
              <div
                className="marketPlaceCarousel__arrow"
                onClick={() => {
                  sliderRef?.current?.slickPrev()
                }}
              >
                <AppIcon iconName={'prevArrow'} />
              </div>
              <div
                className="marketPlaceCarousel__arrow"
                onClick={() => {
                  sliderRef?.current?.slickNext()
                }}
              >
                <AppIcon iconName={'nextArrow'} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default MarketPlaceCarousel
