import React from 'react'
import TabSectionOne from '../../shared/TabSectionOne'
const FunToUseSection = ({
  primaryHeading = 'no heading',
  secondaryHeading = 'no secondary heading',
  funToUseTabs = [],
  description = 'lorem ipsum de'
}) => {
  return (
    <section className="funToUseSection">
      <TabSectionOne
        primaryText={primaryHeading}
        secondaryText={secondaryHeading}
        paragraphText={description}
        tabsData={funToUseTabs}
        overlayClass={'one'}
        activeTabColorClass={'yellow'}
        btnColorClass={'filledYellow'}
      />
    </section>
  )
}

export default FunToUseSection
