import React from 'react'
import Paragraph from '../../shared/Paragraph'
import { Container, Row, Col } from 'react-grid-system'
import SecondaryHeading from '../../shared/SecondaryHeading'
import ThemeImageLoader from '../../shared/ThemeImageLoader'

function MagictaskSection({
  primaryHeading = 'no Title',
  secondaryHeading = 'no description',
  description = 'no back text'
}) {
  return (
    <section className="magicTaskSection" id="magicTaskSection">
      <Container className="magicTaskSection__gridContainer">
        <Row>
          <Col md={12} lg={6}>
            <SecondaryHeading
              primaryText={primaryHeading}
              secondaryText={secondaryHeading}
            />

            <Paragraph
              paragraphText={description}
              textColorClass={'secondary'}
              customClass={'magicTaskSection__paragraph'}
            />
          </Col>
          <Col md={12} lg={6}>
            <ThemeImageLoader
              imgExtention="webp"
              imgName="magicTaskDescktop"
              customClass={'magicTaskSection__img'}
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default MagictaskSection
