import React, { useEffect, useRef, useState } from 'react'
import { getCurrentTheme } from '../../../../utils'

const TabIcon = ({ onChangeRender, name, isActive = false }) => {
  const [activeClassIcon, setActiveClassIcon] = useState(isActive)
  const checkActiveClass = useRef(null)
  const themeName = getCurrentTheme().id

  // console.log('Theme name is -->', themeName)

  useEffect(() => {
    if (!isActive) {
      setActiveClassIcon(
        checkActiveClass.current?.parentElement?.classList?.contains(
          'active_icon'
        )
      )
    }
  }, [onChangeRender])

  return (
    <>
      <img
        width={35}
        height={35}
        ref={checkActiveClass}
        src={`./themes/${themeName}/assets/images/iconTab/${
          activeClassIcon
            ? name.toLowerCase().split(' ').join('-') + '-active'
            : name.toLowerCase().split(' ').join('-')
        }.svg`}
      />
    </>
  )
}

export default TabIcon
