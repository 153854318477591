import React from 'react'
import TabSectionOne from '../../shared/TabSectionOne'
function EasyToUseSection({
  primaryHeading = 'no heading',
  secondaryHeading = 'no secondary heading',
  easyToUseSectionTabs = [],
  description = 'lorem ipsum de'
}) {
  return (
    <section className="easyToUseSection">
      <TabSectionOne
        primaryText={primaryHeading}
        secondaryText={secondaryHeading}
        paragraphText={description}
        tabsData={easyToUseSectionTabs}
      />
    </section>
  )
}
export default EasyToUseSection
