import React from 'react'
import { navigate } from '@reach/router'

const SideLeftIcon = ({ className, children, link = '/' }) => {
  const handleOnClick = () => {
    if (typeof window !== 'undefined' && link !== '' && link !== '/') {
      window.open(link, '_blank')
    } else {
      navigate(link)
    }
  }

  return (
    <>
      <div className={`left-side-icon ${className}`} onClick={handleOnClick}>
        <a>{children}</a>
      </div>
    </>
  )
}

export default SideLeftIcon
