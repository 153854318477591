import React, { useEffect, useRef, useState } from 'react'
import defaultVideoSrc from '../../../../static/videos/slelect-project-create-task.mp4'

const VideoPlayer = ({ videoSrc = 'slelect-project-create-task.mp4' }) => {
  const [myVideo, setMyVideo] = useState(null)
  const [error, setError] = useState(false)

  const getVid = () =>
    import(`../../../../static/videos/${videoSrc}`)
      .then((res) => {
        setMyVideo(res.default)
      })
      .catch((err) => {
        setError(true)
      })

  useEffect(() => {
    getVid()
  }, [])

  return (
    <div className="videoPlayerContainer">
      {myVideo === null ? (
        <div className="videoPlayerContainer__loader"></div>
      ) : (
        <video
          className="videoPlayerContainer__video"
          autoPlay
          loop
          muted
          src={myVideo}
        />
      )}
    </div>
  )
}

export default VideoPlayer
