import React, { useState } from 'react'
import Paragraph from '../../shared/Paragraph'
import Primarybutton from '../../shared/Primarybutton'
import PrimaryHeading from '../../shared/PrimaryHeading'
import ShowVideoModal from '../../shared/ShowVideoModal'
import HeroSocialBar from './HeroSocialBar'
import heroSectionVideo from '../../../videos/heroSectionVideo.mp4'

function HeroSection({
  title = 'no title',
  description = 'no description',
  primaryButton = { buttonLink: '/', buttonText: 'no text' },
  secondaryButton = { buttonLink: '/', buttonText: 'no text' }
}) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <section className="heroSection" id="heroSection">
      <HeroSocialBar />

      <div className="maxWidthcontainer">
        <div className="heroSection__wrapper">
          <PrimaryHeading
            customClass={'homepageHeader__heading'}
            headingText={title}
          />
          <div className="heroSection__body">
            <Paragraph paragraphText={description} alignCenter={true} />
            <div className="heroSection__btnWrapper">
              <Primarybutton
                buttonText={primaryButton.buttonText}
                buttonLink={primaryButton.buttonLink}
                buttonId={'banner'}
                customClass={'heroSection__btn'}
              />
              <Primarybutton
                buttonText={secondaryButton.buttonText}
                buttonLink={secondaryButton.buttonLink}
                buttonType={'outlined'}
                customClass={'heroSection__btn'}
                onClick={() => {
                  setIsModalOpen(true)
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <ShowVideoModal
        videoSrc={heroSectionVideo}
        isOpen={isModalOpen}
        hideModal={() => {
          setIsModalOpen(false)
        }}
      />
    </section>
  )
}

export default HeroSection
