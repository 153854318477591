import React from 'react'
import AppTabs from '../AppTabs'
import Paragraph from '../Paragraph'
import SecondaryHeading from '../SecondaryHeading'

const TabSectionOne = ({
  primaryText,
  secondaryText,
  paragraphText,
  tabsData,
  overlayClass,
  activeTabColorClass,
  btnColorClass,
  TabIcon
}) => {
  return (
    <section className="tabSectionOne">
      <div className="tabSectionOne__overlay">
        <div className={`tabSectionOne__wrapper`}>
          <div
            className={`tabSectionOne__wrapper__overlay  ${
              overlayClass
                ? '  tabSectionOne__wrapper__overlay--' + overlayClass
                : ''
            }`}
          >
            <SecondaryHeading
              primaryText={primaryText}
              secondaryText={secondaryText}
              backcroundTextClass={overlayClass}
            />

            <Paragraph
              paragraphText={paragraphText}
              customClass={'tabSectionOne__text'}
              textColorClass={'secondary'}
              alignCenter={true}
            />
            <div className="tabSectionOne__TabsContainer">
              {/* App TAbs Will come here  */}
              <AppTabs
                activeTabClass={activeTabColorClass}
                tabsData={tabsData}
                btnColorClass={btnColorClass}
                
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TabSectionOne
