import React from 'react'
import Modal from 'react-modal'
Modal.setAppElement('#___gatsby')

const modalStyles = {
  overlay: { zIndex: '100', backgroundColor: 'rgb(18 18 18 / 75%)' }
}

const ShowVideoModal = ({
  videoSrc = null,
  videoUrl = 'https://www.youtube.com/embed/C1U64cULGCM',
  isOpen,
  hideModal = () => {
    console.error('Hide Modal function error')
  }
}) => {
  return (
    <Modal
      style={modalStyles}
      isOpen={isOpen}
      onRequestClose={hideModal}
      contentLabel="Video Modal"
      className={'showVideoModal__content'}
    >
      <div className="showVideoModal__closeBtn" onClick={hideModal}>
        X
      </div>
      <div className="showVideoModal__container">
        {!videoSrc ? (
          <iframe
            src={videoUrl}
            title="YouTube video player"
            frameborder="0"
            allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            className="showVideoModal__video"
          ></iframe>
        ) : (
          <video className="showVideoModal__video" controls autoPlay>
            <source src={videoSrc} type="video/mp4" />
          </video>
        )}
      </div>
    </Modal>
  )
}

export default ShowVideoModal
