import React, { useState } from 'react'
import { getCurrentTheme, setTheme } from '../../../../utils'
const allThemes = require('../../../../config/themes.json')
const MarketPlaceCard = ({
  themeImage = '/images/cleantheme.webp',
  cardHeading,
  btnText,
  btnLink
}) => {
  const [equipThisTheme, setEquipThisTheme] = useState(
    cardHeading.replace('Theme', '').replace(/\s/g, '').toLowerCase()
  )

  const [isThisEquiped, setIsThisEquiped] = useState(
    getCurrentTheme().name.toLowerCase() === equipThisTheme
  )

  const equipTheme = () => {
    const themeToEquip = allThemes.filter(
      (theme) => theme.name.toLowerCase() === equipThisTheme
    )

    if (themeToEquip.length > 0) {
      setTheme(themeToEquip[0].id)
      typeof window !== 'undefined' && window.location.reload()
    }
  }
  const greyedStyle = {
    opacity: isThisEquiped ? 0.5 : 1
  }

  return (
    <div
      style={greyedStyle}
      aria-disabled={isThisEquiped}
      className="marketPlaceCard"
    >
      <div className="marketPlaceCard__wrapper">
        {/* / / */}
        <div className="marketPlaceCard__imgContainer">
          <img
            src={themeImage}
            className="marketPlaceCard__imgContainer__img"
          />
        </div>

        <div className="marketPlaceCard__body">
          <h4 className="marketPlaceCard__heading"> {cardHeading} </h4>
          <button
            onClick={equipTheme}
            className="marketPlaceCard__btn"
            disabled={isThisEquiped}
            id={`equip-${cardHeading.toLowerCase().split(' ').join('-')}`}
          >
            {isThisEquiped ? 'Already Equiped' : btnText}
          </button>
        </div>
      </div>
      
    </div>
  )
}

export default MarketPlaceCard
