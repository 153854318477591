import React from 'react'

import TabSectionOne from '../../shared/TabSectionOne'

const tabsData = [
  {
    tabTitle: 'Real-time collaboration',
    tabContent: {
      heading: 'Real-time collaboration',
      description:
        'See tasks and projects update in real time when working with your team.',
      btnText: 'Get Started',
      btnLink: '/',
      icon: 'realTimeCommunication',
      featuredImage: 'magicTaskDescktop.webp'
    },
    tabIcon: 'realTimeCommunicatinSmall'
  },
  {
    tabTitle: 'Equipment Themes',
    tabIcon: 'taskBlue'
  }
]

const SaveTimeSection = ({
  primaryHeading = 'no heading',
  secondaryHeading = 'no secondary heading',
  saveTimeTabs = [],
  description = 'lorem ipsum de'
}) => {
  return (
    <section className="saveTimeSection">
      <TabSectionOne
        primaryText={primaryHeading}
        secondaryText={secondaryHeading}
        paragraphText={description}
        tabsData={saveTimeTabs}
        overlayClass={'two'}
        activeTabColorClass={'blueTwo'}
        btnColorClass={'filledBlueTwo'}
      />
    </section>
  )
}

export default SaveTimeSection
