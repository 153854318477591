import React from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { getCurrentTheme } from '../../../../utils'
import AppIcon from '../../AppIcon'
import Paragraph from '../../Paragraph'
import Primarybutton from '../../Primarybutton'
import VideoPlayer from '../../VideoPlayer'
import TabIcon from './TabIcon'

const AppTabPanelContentPrimary = ({
  heading = 'Work Spaces',
  description = 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
  btnText = 'Get Started',
  btnLink = '/',
  icon = 'workspaces',
  featuredImage = 'magicTaskDescktop.webp',
  btnColorClass,
  tabVideo,
  BtnId
}) => {
  const currentThemeFolder = getCurrentTheme()?.id?.replace('-', '_')

  return (
    <div className="appTabPanelPrimary">
      <Container className="appTabPanelPrimary__container">
        <Row>
          {typeof window !== 'undefined' && window.screen.width < 980 ? (
            <div className="appTabPanelPrimary__leftSection">
              <div
                className={`appTabPanelPrimary__contentImage ${
                  btnColorClass ? btnColorClass : ''
                }`}
              >
                <TabIcon isActive={true} name={icon} />
              </div>
              {/* <AppIcon iconName={icon}  className='app___icons'/> */}
              <h3 className="appTabPanelPrimary__heading">{heading}</h3>
              <Paragraph
                paragraphText={description}
                textColorClass={'secondary'}
                customClass={'appTabPanelPrimary__desc'}
              />

              <Primarybutton
                buttonText={btnText}
                customClass={'appTabPanelPrimary__btn showOnlyDesktop'}
                noHover={true}
                buttonType={btnColorClass}
                buttonId={BtnId}
                icon={true}
              />
            </div>
          ) : (
            <Col md={12} lg={5}>
              <div className="appTabPanelPrimary__leftSection">
                <div
                  className={`appTabPanelPrimary__contentImage ${
                    btnColorClass ? btnColorClass : ''
                  }`}
                >
                  <TabIcon isActive={true} name={icon} />
                </div>
                {/* <AppIcon iconName={icon}  className='app___icons'/> */}
                <h3 className="appTabPanelPrimary__heading">{heading}</h3>
                <Paragraph
                  paragraphText={description}
                  textColorClass={'secondary'}
                  customClass={'appTabPanelPrimary__desc'}
                />

                <Primarybutton
                  buttonText={btnText}
                  customClass={'appTabPanelPrimary__btn showOnlyDesktop'}
                  noHover={true}
                  buttonType={btnColorClass}
                  buttonId={BtnId}
                />
              </div>
            </Col>
          )}

          <Col md={12} lg={7}>
            <Row className="appTabPanelPrimary__rightSectionRow">
              <div className="appTabPanelPrimary__rightSection">
                {tabVideo &&
                typeof tabVideo !== 'string' &&
                typeof tabVideo !== 'undefined' ? (
                  <div>
                    <VideoPlayer
                      videoSrc={tabVideo[
                        `${currentThemeFolder}_video`
                      ]?.replace('/static/videos/', '')}
                    />
                  </div>
                ) : (
                  <div>
                    {/* <VideoPlayer

                      videoSrc={tabVideo?.replace('/static/videos/', '')}
                    /> */}
                  </div>
                )}
              </div>
            </Row>

            <Row>
              <Primarybutton
                buttonText={btnText}
                customClass={'appTabPanelPrimary__btn showOnlyMobile'}
                noHover={true}
                buttonType={btnColorClass}
              />
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default AppTabPanelContentPrimary
